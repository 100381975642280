import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"
import { map } from "lodash-es"

import extractActions from "./actions"
import extractMutations from "./mutations"

const BASE_URI = "/maestro/users"
export const mapFilters = ({ searchValue, organizations, ota_admin, organization_admin }) => ({
  organization_ids: map(organizations, "id"),
  search_value: searchValue,
  organization_admin,
  ota_admin
})

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch"],
  withFilters: {
    searchValue: null,
    organizations: [],
    ota_admin: false,
    organization_admin: false
  },
  withPagination: true,
  withSorting: {},
  mapFilters
})

store.mergeState({
  item: {}
})

const mutations = extractMutations()
store.mergeMutations(mutations)

const { CHECK_ACCOUNT_ID_EXISTS, CREATE_ITEM, DELETE_ITEM, FETCH_ITEM, UPDATE_ITEM } = extractActions({
  baseURI: BASE_URI
})
store.mergeActions({ CHECK_ACCOUNT_ID_EXISTS })
store.mergeActions({ DELETE_ITEM, CREATE_ITEM, FETCH_ITEM, UPDATE_ITEM }, withLoading)

export default store
